const menuData = [
  {
    title: "Home",
    path: "/",
  },

  {
    title: "About",
    path: "/about",
  },

  {
    title: "Faq",
    path: "/faq",
  },

  {
    title: "Services",
    path: "/services",
  },

  {
    title: "Contact",
    path: "/contact",
  },
];

export default menuData;
